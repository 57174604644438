import React from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  OutlinedInput,
  StandardTextFieldProps,
} from '@mui/material';
import { useIMask } from 'react-imask';
import { MoFieldErrorText } from '../MoFieldErrorText';

export interface MoTextInputProps
  extends Omit<StandardTextFieldProps, 'onKeyDown' | 'onKeyUp' | 'onInvalid' | 'variant'> {
  name: string;
  label: string;
  helperText?: string | string[];
  hideLabel?: boolean;
  floatingLabel?: boolean;
}

export function MoTextInput({
  name,
  label,
  className,
  helperText,
  error,
  InputProps,
  inputProps,
  hideLabel,
  onChange,
  floatingLabel = true,
  ...props
}: MoTextInputProps) {
  let errorMessage: string | null = null;
  if (helperText) {
    errorMessage = typeof helperText === 'string' ? helperText : helperText[0];
  }

  const telRef = useIMask(
    {
      mask: [
        {
          mask: '(#00) 000-0000',
          definitions: {
            '#': /[1-9]/,
          },
        },
        {
          mask: '+1 (#00) 000-0000',
          definitions: {
            '#': /[1-9]/,
          },
        },
      ],
    },
    {
      onAccept: (value: string) => {
        if (onChange) onChange({ target: { value } } as any);
      },
    },
  ).ref;

  const ref = props.type === 'tel' ? telRef : null;

  return (
    <FormControl fullWidth>
      {!hideLabel && (
        <>
          {floatingLabel ? (
            <InputLabel htmlFor={name} error={error}>
              {label}
            </InputLabel>
          ) : (
            <FormLabel htmlFor={name} error={error}>
              {label}
            </FormLabel>
          )}
        </>
      )}
      <OutlinedInput
        {...props}
        {...InputProps}
        inputProps={{
          ...inputProps,
          id: name,
          'aria-label': label,
          ref: ref || inputProps?.ref,
        }}
        error={error}
        name={name}
        margin="none"
        label={floatingLabel ? label : undefined}
        onChange={onChange}
      />
      {!error && helperText && (
        <FormHelperText component="div" sx={{ ml: 0 }}>
          {helperText}
        </FormHelperText>
      )}
      {error && errorMessage && <MoFieldErrorText>{errorMessage}</MoFieldErrorText>}
    </FormControl>
  );
}
