import { OrganizationAdminPermissionName } from ':src/__generated__/graphql';
import useViewer from './useViewer';

export { OrganizationAdminPermissionName } from ':src/__generated__/graphql';

export default function usePermissions() {
  const { viewer } = useViewer();
  return {
    hasPermission: (permissionName: OrganizationAdminPermissionName, programId?: string) => {
      const permissionsIncludesName = viewer.permissions?.permissionNames.includes(permissionName);
      if (!permissionsIncludesName) {
        return false;
      }
      return (
        programId == null ||
        viewer.permissions?.programIds == null ||
        viewer.permissions?.programIds.includes(programId)
      );
    },
  };
}
