import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

export function getLimitAndOffsetFromPageParams(page: number, pageSize: number) {
  return {
    limit: pageSize,
    offset: page * pageSize,
  };
}
export interface QueryParamsObject {
  [key: string]: string;
}

export function parseQueryParamAsBoolean(oneOrZero: string, defaultValue?: boolean): boolean {
  const parsedNumber = Number(oneOrZero);
  return Number.isNaN(parsedNumber) ? defaultValue || false : !!parsedNumber;
}

/**
 * @deprecated - use the one in guanyin
 */
export default function useQueryParams() {
  const location = useLocation();
  const params = useMemo(
    () => queryString.parse(location.search) as QueryParamsObject,
    [location.search],
  );
  const history = useHistory();

  const updateQueryParams = (newParams: QueryParamsObject, push = false) => {
    const mergedParams = {
      ...params,
      ...newParams,
    };
    Object.keys(mergedParams).forEach((key) => {
      if (mergedParams[key] === null) delete mergedParams[key];
    });

    const newQueryString = queryString.stringify(mergedParams);
    const newURL = `${location.pathname}?${newQueryString}`;

    if (push) {
      history.push(newURL);
    } else {
      history.replace(newURL);
    }
  };

  const pushUpdateQueryParams = (newParams: QueryParamsObject) => {
    updateQueryParams(newParams, true);
  };

  return {
    queryParams: params,
    updateQueryParams,
    pushUpdateQueryParams,
  };
}
