import React, { useState } from 'react';
import {
  MoPageContainer,
  MoPersistentTooltip,
  MoSpacing,
  MoSubmitButton,
  MoSwitchField,
} from '@motivo/guanyin/src/components';
import { Alert, Box, Button, IconButton, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import useViewer from ':src/hooks/useViewer';
import { useProgram } from ':src/domains/programs/hooks/useProgram';
import { updateOrganizationProgramSettingsMutation } from ':src/domains/programs/mutations';

import SnackbarState from ':src/hooks/SnackbarState';
import { IntroCallPreferencesFormField } from '../components/IntroCallPreferencesFormField';

export const programSettingsPagePath = (id: string | number) => `/programs/settings/${id}`;

export interface FormValues {
  programId: string;
  requiresNotesInReports: boolean;
  introCallPreferenceId: number;
  requiresSupervisorAdminHours: boolean;
}

export default function ProgramSettingsPage() {
  const { viewer } = useViewer();
  const { openSnackbar } = SnackbarState.useContainer();
  const { organization } = viewer;

  const [isOverrideButtonVisible, setIsOverrideButtonVisible] = useState(true);

  const params = useParams<{ programId: string }>();
  const { program, refetch: refetchProgram } = useProgram(params.programId);

  const [updateOrganizationProgramSettings, { loading: loadingUpdate }] = useMutation(
    updateOrganizationProgramSettingsMutation,
  );

  const handleSubmit = async (values: FormValues) => {
    try {
      await updateOrganizationProgramSettings({
        variables: {
          input: {
            programId: params.programId,
            requiresNotesInReports: values.requiresNotesInReports,
            introCallPreferenceId: values.introCallPreferenceId,
            requiresSupervisorAdminHours: values.requiresSupervisorAdminHours,
          },
        },
      });
      openSnackbar('Your program settings have been updated successfully!');
      refetchProgram();
    } catch (e) {
      openSnackbar('Something went wrong updating your program settings');
    }
  };

  const { name } = program;
  const introCallPreferenceId = program.introCallPreferenceId ?? organization.introCallPreferenceId;

  const programUsesOrganizationSettings =
    program.introCallPreferenceId == null &&
    program.requiresNotesInReports == null &&
    program.requiresSupervisorAdminHours == null;

  const isEditDisabled = isOverrideButtonVisible && programUsesOrganizationSettings;

  return (
    <MoPageContainer hasWhiteBackground maxWidth="sm">
      <Typography variant="h2" mb={4}>
        Edit {name}
      </Typography>

      <Form<FormValues>
        onSubmit={handleSubmit}
        initialValues={{
          requiresNotesInReports:
            program.requiresNotesInReports ?? organization.requiresNotesInReports,
          requiresSupervisorAdminHours:
            program.requiresSupervisorAdminHours ?? organization.requiresSupervisorAdminHours,
          introCallPreferenceId,
        }}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box mb={4} display="flex" flexDirection="column">
                <Typography variant="h3" mb={3}>
                  General
                </Typography>
                <MoSpacing y={2}>
                  <Box display="flex" gap={1}>
                    <MoSwitchField
                      disabled={isEditDisabled}
                      name="requiresNotesInReports"
                      label="We require supervisors to submit supervision session notes"
                    />

                    <MoPersistentTooltip
                      titleContent="This means that if you need the supervisor to take notes for each supervision session, the documentation will be available on your dashboard."
                      placement="top"
                      arrow
                    >
                      {({ toggleTooltip }) => (
                        <IconButton onClick={toggleTooltip} aria-label="Close">
                          <InfoOutlinedIcon />
                        </IconButton>
                      )}
                    </MoPersistentTooltip>
                  </Box>
                  <MoSwitchField
                    disabled={isEditDisabled}
                    name="requiresSupervisorAdminHours"
                    label="We require supervisors to do admin hours"
                  />
                  <Box display="flex" gap={1}>
                    <IntroCallPreferencesFormField introCallPreferenceId={introCallPreferenceId} />
                  </Box>
                </MoSpacing>
              </Box>
              {isEditDisabled && (
                <Alert severity="warning">
                  Your organization {organization.name} has already set these options. Click below
                  if you want to change these specifically for <strong>{program.name}</strong>{' '}
                  program. Note: If you continue, this program’s settings will no longer track with
                  the global settings.
                </Alert>
              )}
              <Box mt={6} display="flex" justifyContent="flex-end">
                {isEditDisabled ? (
                  <Button
                    color="neutral"
                    variant="outlined"
                    onClick={() => setIsOverrideButtonVisible(false)}
                    fullWidth={false}
                  >
                    Override settings
                  </Button>
                ) : (
                  <MoSubmitButton disabled={loadingUpdate} fullWidth={false}>
                    Save changes
                  </MoSubmitButton>
                )}
              </Box>
            </form>
          );
        }}
      />
    </MoPageContainer>
  );
}
