import { MoLink } from '@motivo/guanyin/src/components';
import { Typography } from '@mui/material';
import React from 'react';
import { ATTENDEE_VISIBILITY_THRESHOLD } from '../../utils';
import { ButtonTypography } from './ButtonTypography';

export const getSuperviseeNames = (
  sessionAttendees: Array<{ user: { fullName: string; id: number } }>,
  expandAttendees: boolean,
  setExpandAttendees: (expand: boolean) => void,
  secondaryColor: string,
) => {
  const remainingSuperviseesCount = sessionAttendees.length - ATTENDEE_VISIBILITY_THRESHOLD;

  const superviseeElements = sessionAttendees
    .slice(0, expandAttendees ? sessionAttendees.length : ATTENDEE_VISIBILITY_THRESHOLD)
    .flatMap(({ user }, index, array) => [
      <MoLink key={user.id} to={`/sessions?userId=${user.id}`} sx={{ fontWeight: 'bold' }}>
        {user.fullName}
      </MoLink>,
      index < array.length - 1 ? (
        <span key={`comma-${user.id}`} style={{ color: secondaryColor }}>
          ,{' '}
        </span>
      ) : null,
    ]);

  if (!expandAttendees && remainingSuperviseesCount > 0) {
    superviseeElements.push(
      <ButtonTypography
        sx={{
          marginLeft: '4px',
          color: secondaryColor,
        }}
        onClick={() => setExpandAttendees(true)}
      >
        + {remainingSuperviseesCount}
      </ButtonTypography>,
    );
  }

  return <Typography component="span">{superviseeElements}</Typography>;
};
