export const downloadFile = (file: Blob, fileName: string) => {
  const a = document.createElement('a');
  const url = URL.createObjectURL(file);
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, 0);
};

export const downloadCSV = (csvString: string, fileName: string) => {
  const file = new Blob([csvString], { type: 'text/csv' });
  downloadFile(file, fileName);
};
