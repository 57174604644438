import { introRequestsDashboardNotificationQuery } from ':src/domains/introRequests/queries';
import { chatsWithUnreadMessagesQuery } from ':src/domains/messages/queries';
import { upcomingTabPath } from ':src/domains/sessions';
import usePermissions, { OrganizationAdminPermissionName } from ':src/hooks/usePermissions';
import useRequiresPaymentInformation from ':src/hooks/useRequiresPaymentInformation';
import useViewer from ':src/hooks/useViewer';
import { useQuery } from '@apollo/client';
import {
  BaseHeaderLink,
  Header as MoHeader,
  HeaderLink,
  MoNotificationBadge,
} from '@motivo/guanyin/src/components';
import { HeaderLinkType, HeaderMenuLink } from '@motivo/guanyin/src/components/Header/HeaderMenu';
import { login } from '@motivo/styx/src/auth';
import { Avatar, Badge, Box, Divider, ListItem } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
  const { viewer } = useViewer();
  const { fullName, mediumAvatar } = viewer;
  const { hasPermission } = usePermissions();

  const { data: introRequestsDashboardNotificationData } = useQuery(
    introRequestsDashboardNotificationQuery,
    {
      fetchPolicy: 'network-only',
    },
  );
  const { data: chatsWithUnreadMessagesData } = useQuery(chatsWithUnreadMessagesQuery);
  const requiresPaymentInformation = useRequiresPaymentInformation();

  const introRequestsDashboardNotificationTotal =
    // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
    introRequestsDashboardNotificationData?.introRequests.total;
  const unreadChatsTotal = chatsWithUnreadMessagesData?.chatsWithUnreadMessages.length;
  const totalNotificationCount =
    (introRequestsDashboardNotificationTotal || 0) + (unreadChatsTotal || 0);

  const { pathname } = useLocation();
  const isSessions = pathname.includes('sessions');
  const isSupervisees = pathname.includes('supervisees');
  const isSupervisors = pathname.includes('supervisors');
  const isDashboard = pathname === '/';

  // ----------------------------------------
  // menu config
  // ----------------------------------------
  const headerLinks: HeaderLinkType[] = [
    { text: 'Dashboard', path: '/', isActive: isDashboard },
    { text: 'Sessions', path: upcomingTabPath, isActive: isSessions },
    ...(hasPermission(OrganizationAdminPermissionName.ProgramView)
      ? [
          {
            text: 'Supervisees',
            path: '/supervisees',
            isActive: isSupervisees,
          },
          {
            text: 'Supervisors',
            path: '/supervisors',
            isActive: isSupervisors,
          },
        ]
      : []),
  ];

  // menu has 4 sections per spec
  const menuSections: [HeaderLinkType[], HeaderLinkType[], HeaderLinkType[], HeaderLinkType[]] = [
    [],
    [],
    [],
    [],
  ];

  // first section
  if (hasPermission(OrganizationAdminPermissionName.IntroCalls)) {
    menuSections[0].push({
      text: 'Messages',
      path: '/chats',
      badgeCount: unreadChatsTotal,
    });
  }

  if (!requiresPaymentInformation && hasPermission(OrganizationAdminPermissionName.IntroCalls)) {
    menuSections[0].push({
      text: 'Intro calls',
      path: '/intro-calls',
      badgeCount: introRequestsDashboardNotificationTotal,
    } as $TSFixMe);
  }

  // second section
  if (hasPermission(OrganizationAdminPermissionName.ProgramViewAll)) {
    menuSections[1].push({ text: 'Programs', path: '/programs' });
  }
  if (viewer.canAccessBillableWork) {
    menuSections[1].push({ text: 'Administrative hours', path: '/admin-hours' });
  }

  // third section
  menuSections[2].push({ text: 'Settings', path: '/settings' });
  if (!requiresPaymentInformation && hasPermission(OrganizationAdminPermissionName.IntroCalls)) {
    menuSections[2].push({
      text: 'Directory',
      url: 'https://app.motivohealth.com/employer-search',
    });
  }

  // fourth section
  menuSections[3].push({ text: 'Log out', path: '/logout' });

  // build final menu
  const menuLinks = menuSections
    .filter((section) => section.length > 0)
    .flatMap((section, i) => {
      if (i === 0) {
        return section;
      }
      return [{ divider: true }, ...section];
    });

  // ----------------------------------------
  // render
  // ----------------------------------------
  return (
    <MoHeader
      logoPath="/"
      isLoggedIn
      links={headerLinks.map((link, i) => {
        if ('divider' in link) return null;
        const { path, isActive, text } = link;
        return (
          <HeaderLink key={i} component={Link} to={path} isActive={isActive}>
            {text}
          </HeaderLink>
        );
      })}
      loginLink={<HeaderLink onClick={() => login()}>Log in</HeaderLink>}
      logoutLink={
        <HeaderLink component={Link} to="/logout" isLogout>
          Log out
        </HeaderLink>
      }
      menuLinks={menuLinks.map((link, i) => {
        if ('divider' in link) return <Divider key={i} sx={{ my: 0.5 }} />;

        const { text, badgeCount } = link;

        if ('onClick' in link) {
          return (
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            <HeaderMenuLink key={i} onClick={() => link.onClick()} isLogout>
              {text}
            </HeaderMenuLink>
          );
        }

        return (
          <HeaderMenuLink
            key={i}
            {...(link.url
              ? { component: 'a', href: link.url }
              : { component: Link, to: link.path })}
          >
            {text}
            {/* // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
            {(badgeCount as $TSFixMe) > 0 && (
              <Box ml={1}>
                <MoNotificationBadge>{badgeCount}</MoNotificationBadge>
              </Box>
            )}
          </HeaderMenuLink>
        );
      })}
      menuButton={
        <>
          {mediumAvatar && (
            <Badge color="error" badgeContent={totalNotificationCount}>
              <Avatar src={mediumAvatar} />
            </Badge>
          )}
          <Box ml={2} textAlign="left">
            {fullName}
          </Box>
        </>
      }
      mobileLinks={[...headerLinks, { divider: true }, ...menuLinks].map((link, i) => {
        if ('divider' in link) return <Divider key={i} sx={{ my: 0.5 }} />;

        const { text, badgeCount } = link;

        if ('onClick' in link) {
          return (
            <ListItem key={i}>
              {/* @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly
              'undefin... Remove this comment to see the full error message */}
              <BaseHeaderLink onClick={() => (link as $TSFixMe)?.onClick()} isLogout>
                {text}
              </BaseHeaderLink>
            </ListItem>
          );
        }

        return (
          <ListItem key={i}>
            <BaseHeaderLink
              {...(link.url
                ? { component: 'a', href: link.url }
                : { component: Link, to: link.path })}
            >
              {text}
              {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
              {badgeCount > 0 && (
                <Box ml={1}>
                  <MoNotificationBadge> {badgeCount}</MoNotificationBadge>
                </Box>
              )}
            </BaseHeaderLink>
          </ListItem>
        );
      })}
    />
  );
}
