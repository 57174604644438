import SnackbarState from ':src/hooks/SnackbarState';
import useViewer from ':src/hooks/useViewer';
import { UsersByOrganizationIdQueryFilter } from ':src/__generated__/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { EmptyOrLoadingState, MoPageContainer } from '@motivo/guanyin/src/components';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ManageProgramsUsers } from '../components/ManageProgramUsers';
import { useProgram } from '../hooks/useProgram';
import { organizationProgramsWithDetailsQuery } from '../hooks/usePrograms';
import { updateSuperviseesInProgramMutation } from '../mutations';
import {
  organizationSuperviseesOutsideProgram,
  superviseesByOrganizationProgramId,
} from '../queries';

export const editProgramSuperviseesPath = (id: string | number) =>
  `/programs/${id}/edit-supervisees`;

export default function EditSuperviseesPage() {
  const { viewer } = useViewer();
  const { id: programId } = useParams<{ id: string }>();
  const { openSnackbar } = SnackbarState.useContainer();
  const { program } = useProgram(programId);

  const usersNotInProgramVariables = {
    organizationId: viewer.organizationId,
    limit: 1000,
    offset: 0,
    filter: UsersByOrganizationIdQueryFilter.NotInProgram,
    programId,
  };

  const usersInProgramVariables = {
    organizationProgramId: programId,
  };

  const { data: usersNotInProgram, loading: usersNotInProgramLoading } = useQuery(
    organizationSuperviseesOutsideProgram,
    {
      variables: usersNotInProgramVariables,
      fetchPolicy: 'network-only',
    },
  );

  const { data: usersInProgram, loading: usersInProgramLoading } = useQuery(
    superviseesByOrganizationProgramId,
    {
      variables: usersInProgramVariables,
      fetchPolicy: 'network-only',
    },
  );

  const [updateSuperviseesInProgram, { loading: updateSuperviseesInProgramLoading }] = useMutation(
    updateSuperviseesInProgramMutation,
  );

  async function handleUpdateSupervisees(userIds: number[]) {
    try {
      const { data, errors } = await updateSuperviseesInProgram({
        variables: {
          input: {
            programId,
            userIds,
          },
        },
        refetchQueries: [
          organizationProgramsWithDetailsQuery,
          organizationSuperviseesOutsideProgram,
          superviseesByOrganizationProgramId,
        ],
      });
      // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
      if (data.updateSuperviseesInProgram.success) {
        openSnackbar('Supervisees updated successfully');
      }
      // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
      if (errors || !data.updateSuperviseesInProgram.success) {
        throw new Error();
      }
    } catch (error) {
      openSnackbar('Something went wrong updating supervisees for this program');
    }
  }

  if (updateSuperviseesInProgramLoading) {
    return (
      <MoPageContainer maxWidth="md">
        <Box py={4}>
          <EmptyOrLoadingState loading />
        </Box>
      </MoPageContainer>
    );
  }

  return (
    <MoPageContainer maxWidth="md">
      <Typography variant="h2" mb={5} fontWeight="bold">
        Edit supervisees in{' '}
        <Typography color="primary" component="span" variant="h2">
          {program?.name}
        </Typography>
      </Typography>
      <ManageProgramsUsers
        usersNotInProgram={usersNotInProgram?.usersByOrganizationId.result}
        // @ts-expect-error TS(2322) FIXME: Type '({ __typename?: "User" | undefined; id: numb... Remove this comment to see the full error message
        usersInProgram={usersInProgram?.superviseesByOrganizationProgramId.result}
        usersInProgramLoading={usersInProgramLoading}
        usersNotInProgramLoading={usersNotInProgramLoading}
        onSubmit={handleUpdateSupervisees}
        isSupervisorPage={false}
      />
    </MoPageContainer>
  );
}
