import React, { useState } from 'react';
import { TooltipProps, Tooltip, IconButton, Box, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

export type MoPersistentTooltipProps = {
  titleContent: React.ReactNode;
  children: (options: { toggleTooltip: () => void }) => React.ReactElement;
} & Omit<TooltipProps, 'title' | 'children'>;

const tooltipDropShadowFilter = 'drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15))';

export function MoPersistentTooltip({
  titleContent,
  children,
  ...props
}: MoPersistentTooltipProps) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleTooltip = () => setIsOpen(!isOpen);

  return (
    <Tooltip
      {...props}
      title={
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="flex-end">
            <Box padding={1} mt={-1} mr={-1} mb={-1}>
              <IconButton onClick={() => setIsOpen(false)}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="body1" component="div">
            {titleContent}
          </Typography>
        </Box>
      }
      componentsProps={{
        tooltip: {
          sx: {
            filter: tooltipDropShadowFilter,
            bgcolor: 'common.white',
            color: 'text.primary',
            padding: 1.5,
            '& .MuiTooltip-arrow': {
              color: 'common.white',
              filter: tooltipDropShadowFilter,
              fontSize: 23,
              width: 25,
            },
            borderRadius: 2,
          },
        },
      }}
      onClose={() => setIsOpen(false)}
      open={isOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      classes={{}}
    >
      {children({ toggleTooltip })}
    </Tooltip>
  );
}
