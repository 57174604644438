import { useSuspenseQuery } from '@apollo/client';

import { organizationProgramById } from '../queries';

export function useProgram(organizationProgramId: string) {
  const { data, refetch } = useSuspenseQuery(organizationProgramById, {
    variables: {
      organizationProgramId,
    },
  });

  const program = data.organizationProgramById;

  return { program, refetch };
}
