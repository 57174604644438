import React, { ReactNode } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import theme from '@motivo/guanyin/src/theme';

const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        fontFamily: ['proxima-nova', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        color: '#434343',
        margin: 0,
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        textAlign: 'left',
        backgroundColor: '#fff',
      },
      a: {
        textDecoration: 'none',
      },
      strong: {
        fontWeight: 600,
      },
      '.StripeElement': {
        padding: '16px',
        border: '1px solid #BBB9BA',
        borderRadius: '4px',
      },
      '.StripeElement--focus': {
        padding: '15px',
        border: '2px solid #577ED9',
      },
      '.StripeElement--invalid': {
        borderColor: '#E46252 !important',
      },
    }}
  />
);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <MuiThemeProvider theme={theme}>
      {globalStyles}
      {children}
    </MuiThemeProvider>
  );
};
