import { useState } from 'react';
import { createContainer } from 'unstated-next';

function useSnackbar() {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const openSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarMessage('');
    setIsSnackbarOpen(false);
  };
  return {
    isSnackbarOpen,
    snackbarMessage,
    openSnackbar,
    closeSnackbar,
  };
}

export default createContainer(useSnackbar);
