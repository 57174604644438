'use client';

import { Button, ButtonTypeMap, styled } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export const MoTabLinkContainer = styled('div')`
  display: flex;
  gap: 1.5rem;
`;

export const MoTabLink = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected: boolean;
}>(({ theme, isSelected }) => ({
  color: isSelected ? theme.palette.primary.main : theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: '16px',
  alignItems: 'center',
  minWidth: 0,
  padding: 0,
  textDecoration: isSelected ? 'underline' : 'none',
  textUnderlineOffset: '8px',
  '&:hover': {
    color: theme.palette.primary.main,
  },
})) as OverridableComponent<ButtonTypeMap<{ isSelected: boolean }>>;
