import React from 'react';
import { Field } from 'react-final-form';
import { MoFormControlLabel } from '../MoFormControlLabel';
import { MoRadio } from '../MoRadio';

export type MoRadioFieldProps<T> = {
  value: T;
  name: string;
  label: string;
  parse: (value: string) => T;
};

export function MoRadioField<T>({ value, name, label, parse }: MoRadioFieldProps<T>) {
  return (
    <Field<T>
      name={name}
      type="radio"
      value={value}
      parse={parse}
      subscription={{
        error: true,
        value: true,
        dirty: true,
      }}
      render={({ input }) => (
        <MoFormControlLabel
          checked={input.checked}
          control={<MoRadio {...input} />}
          label={label}
        />
      )}
    />
  );
}
