import { useCanEditTeamMember } from ':src/hooks/useCanEditTeamMember';
import usePermissions, { OrganizationAdminPermissionName } from ':src/hooks/usePermissions';
import { SettingsLayout as MoSettingsLayout } from '@motivo/guanyin/src/components';
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

export type SettingsLayoutType = {
  children: ReactNode;
};

export default function SettingsLayout({ children }: SettingsLayoutType) {
  const { pathname } = useLocation();
  const shouldShowTeamPage = useCanEditTeamMember();
  const { hasPermission } = usePermissions();

  const pages = [
    {
      name: 'General',
      path: '/settings',
      isActive: pathname === '/settings',
    },
    ...(hasPermission(OrganizationAdminPermissionName.EditBilling)
      ? [
          {
            name: 'Billing',
            path: '/settings/billing',
            isActive: pathname === '/settings/billing',
          },
        ]
      : []),
    ...(hasPermission(OrganizationAdminPermissionName.ManageNotifications)
      ? [
          {
            name: 'Notifications',
            path: '/settings/notifications',
            isActive: pathname === '/settings/notifications',
          },
        ]
      : []),
    {
      name: 'Team',
      path: '/settings/team',
      isActive: pathname === '/settings/team',
      disabled: !shouldShowTeamPage,
    },
  ];

  return <MoSettingsLayout pages={pages}>{children}</MoSettingsLayout>;
}
