'use client';

import React from 'react';
import { Paper, Typography, Box, PaperProps, useTheme, useMediaQuery } from '@mui/material';

export type MoCardProps = {
  title?: string;
  subtitle?: string;
  padding?: string;
  useMobileStyles?: boolean;
  mobileBreakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number;
  children: React.ReactNode;
};

const Wrapper = ({
  padding = '2rem',
  children,
  useMobileStyles = false,
  mobileBreakpoint = 'sm',
  ...props
}: MoCardProps & PaperProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));

  if (useMobileStyles && isMobile) return <Box mb={8}>{children}</Box>;

  return (
    <Paper {...props}>
      <Box padding={padding}>{children}</Box>
    </Paper>
  );
};

export function MoCard({ title, subtitle, children, ...props }: MoCardProps & PaperProps) {
  return (
    <Wrapper {...props}>
      {(title || subtitle) && (
        <Box mb={3}>
          {title && <Typography variant="h3">{title}</Typography>}
          {subtitle && (
            <Box mt={title ? 1 : 0}>
              <Typography variant="body2">{subtitle}</Typography>
            </Box>
          )}
        </Box>
      )}
      {children}
    </Wrapper>
  );
}
