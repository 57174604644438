import React from 'react';
import { Box, FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material';
import { Field } from 'react-final-form';

export interface MoSwitchFieldProps extends SwitchProps {
  name: string;
  label: FormControlLabelProps['label'];
}

export function MoSwitchField({ label, name, ...rest }: MoSwitchFieldProps) {
  return (
    <Field<boolean>
      name={name}
      subscription={{
        error: true,
        value: true,
        touched: true,
        submitFailed: true,
        valid: true,
      }}
      render={({ input }) => {
        return (
          <>
            <Box>
              <FormControlLabel
                label={label}
                labelPlacement="start"
                sx={{ ml: 0, flexDirection: 'initial' }}
                control={
                  <Switch
                    checked={input.value}
                    onChange={(_, checked) => input.onChange(checked)}
                    {...rest}
                  />
                }
              />
            </Box>
          </>
        );
      }}
    />
  );
}
