import { Typography, styled } from '@mui/material';
import React from 'react';

const StyledTypography = styled(Typography)({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
  fontWeight: 'bold',
  border: 'none',
  backgroundColor: 'transparent',
}) as typeof Typography;

export const ButtonTypography = ({
  children,
  onClick,
  sx,
}: {
  children: React.ReactNode;
  onClick: () => void;
  sx?: React.CSSProperties;
}) => (
  <StyledTypography component="button" sx={sx} onClick={onClick}>
    {children}
  </StyledTypography>
);
