import { AvatarProps, Box, styled, Typography } from '@mui/material';
import React from 'react';
import { MoAvatar, LARGE_AVATAR_SIZE, EmptyOrLoadingState } from '@motivo/guanyin/src/components';
import { useQuery } from '@apollo/client';
import { pluralize } from '@motivo/guanyin/src/utils/stringUtils';
import { DateTime } from 'luxon';
import invariant from 'tiny-invariant';
import { UserByIdQueryQuery } from ':src/__generated__/graphql';
import { sessionsSummaryByIdQuery } from '../queries';

export interface AvatarHeaderProps extends Omit<AvatarProps, 'src' | 'children'> {
  user: UserByIdQueryQuery['userById'];
  isSupervisor: boolean;
}

const HoursBox = styled(Box)`
  & + & {
    border-left: 1px solid ${({ theme }) => theme.palette.grey[400]};
  }
`;

const HoursText = ({ hours }: { hours: number }) => {
  return (
    <>
      {hours.toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        maximumFractionDigits: 2,
      })}{' '}
      {hours > 0 && pluralize('hour', hours)}
    </>
  );
};

const CompletedTotalHoursItem = ({ minutes }: { minutes: number }) => {
  const hours = minutes / 60;
  return (
    <Box display="flex" flexDirection="column" alignItems="center" px={3} py={2} border={1}>
      <Typography variant="body1">completed total</Typography>
      <Typography variant="h3" fontWeight="bold">
        <HoursText hours={hours} />
      </Typography>
    </Box>
  );
};

const SessionTypeHoursItem = ({ label, minutes }: { label: string; minutes: number }) => {
  const hours = minutes / 60;
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mx={3}>
      <Typography variant="body1">{label}</Typography>
      <Typography fontWeight="bold">
        <HoursText hours={hours} />
      </Typography>
    </Box>
  );
};

type RemovalInformationProps = {
  organizationUser: UserByIdQueryQuery['userById']['organizationUser'];
};

function RemovalInformation({ organizationUser }: RemovalInformationProps) {
  invariant(organizationUser);
  const { scheduledForRemovalOn, deletedAt } = organizationUser;

  return (
    <Typography variant="body1" color="grey.600">
      {scheduledForRemovalOn && !deletedAt
        ? `To be removed on ${DateTime.fromISO(scheduledForRemovalOn).toLocaleString(
            DateTime.DATE_MED,
          )}`
        : `Removed on ${DateTime.fromISO(deletedAt).toLocaleString(DateTime.DATE_MED)}`}
    </Typography>
  );
}

export default function AvatarHeader({ user, isSupervisor }: AvatarHeaderProps) {
  const { data, loading } = useQuery(sessionsSummaryByIdQuery, {
    variables: {
      userId: user.id,
    },
  });

  if (loading) return <EmptyOrLoadingState loading />;

  const SessionSummarySection = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'totalMinutes' does not exist on type '{ ... Remove this comment to see the full error message
    const { totalMinutes, individualMinutes, dyadMinutes, groupMinutes } =
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      data.superviseeSessionsSummaryById;

    return (
      <Box display="flex" alignItems="center">
        <Box mr={4}>
          <CompletedTotalHoursItem minutes={totalMinutes} />
        </Box>
        <Box display="flex">
          {individualMinutes > 0 && (
            <HoursBox>
              <SessionTypeHoursItem label="individual" minutes={individualMinutes} />
            </HoursBox>
          )}
          {dyadMinutes > 0 && (
            <HoursBox>
              <SessionTypeHoursItem label="dyad" minutes={dyadMinutes} />
            </HoursBox>
          )}
          {groupMinutes > 0 && (
            <HoursBox>
              <SessionTypeHoursItem label="group" minutes={groupMinutes} />
            </HoursBox>
          )}
        </Box>
      </Box>
    );
  };

  const supervisorLicenseCount = user?.usersLicenses.length;
  const supervisorLicenses = user?.usersLicenses
    .map((licenses) => `${licenses.license.name} (${licenses.usState.abbreviation})`)
    .join(', ')
    .toUpperCase();

  const isRemovalInformationVisible =
    user.organizationUser?.scheduledForRemovalOn || user.organizationUser?.deletedAt;

  return (
    <>
      {user && (
        <Box display="flex" m={4} alignItems="center" gap={3}>
          <MoAvatar user={user} size={LARGE_AVATAR_SIZE} />
          <Box>
            <Typography variant="h6" fontWeight="bold">
              {user.fullName}
            </Typography>
            {!isSupervisor && isRemovalInformationVisible && (
              <RemovalInformation organizationUser={user.organizationUser} />
            )}
          </Box>
          {isSupervisor && (
            <div>
              <Typography fontWeight="bold">
                {pluralize('License', supervisorLicenseCount)}
              </Typography>
              <Typography>{supervisorLicenses}</Typography>
            </div>
          )}
          {!isSupervisor && user.organizationSuperviseeOnboardingRequest && (
            <div>
              <Typography fontWeight="bold">
                Acquiring {pluralize('license', supervisorLicenseCount)}
              </Typography>
              <Typography>
                {user.organizationSuperviseeOnboardingRequest.licenseCategory.abbreviation} (
                {user.organizationSuperviseeOnboardingRequest.usState.abbreviation})
              </Typography>
            </div>
          )}
          {!isSupervisor && <SessionSummarySection />}
        </Box>
      )}
    </>
  );
}
