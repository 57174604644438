import React from 'react';
import { styled } from '@mui/material';

const Container = styled('div', {
  shouldForwardProp: (prop) => !['height', 'desktopOnly'].includes(prop as string),
})<{
  height: string;
  desktopOnly: boolean;
}>(({ theme, height, desktopOnly }) => ({
  [theme.breakpoints.up(desktopOnly ? 'sm' : 'xs')]: {
    maxHeight: height,
    overflowY: 'scroll',
    paddingRight: '0.5rem',

    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#c4c4c4',
    },
  },
}));

export type MoScrollContainerProps = {
  children: React.ReactNode;
  height: string;
  desktopOnly?: boolean;
};

export function MoScrollContainer({
  children,
  height,
  desktopOnly = true,
}: MoScrollContainerProps) {
  return (
    <Container height={height} desktopOnly={desktopOnly}>
      {children}
    </Container>
  );
}
