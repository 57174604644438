import { useQuery } from '@apollo/client';
import useViewer from ':src/hooks/useViewer';
import { gql } from ':src/__generated__/gql';
import {
  OrganizationProgramsWithDetailsQueryQuery,
  ViewerQueryQuery,
} from ':src/__generated__/graphql';

export type ProgramType = Pick<
  ViewerQueryQuery['viewer']['organizationPrograms'][number],
  'id' | 'name'
>;

export const organizationProgramsWithDetailsQuery = gql(/* GraphQL */ `
  query OrganizationProgramsWithDetailsQuery {
    viewer {
      id
      organizationPrograms {
        id
        name
        introCallPreferenceId
        requiresNotesInReports
        organizationAdmins {
          id
          fullName
        }
        organizationUsers {
          id
          user {
            id
            firstName
            lastName
            mediumAvatar
          }
        }
      }
    }
  }
`);

export function usePrograms() {
  const { viewer } = useViewer();
  return { programs: viewer.organizationPrograms };
}

export type ProgramWithDetailsType = NonNullable<
  OrganizationProgramsWithDetailsQueryQuery['viewer']['organizationPrograms']
>[number];

export function useProgramsWithDetails() {
  const { data, loading } = useQuery(organizationProgramsWithDetailsQuery);
  const programs = data?.viewer.organizationPrograms ?? [];

  return { programs, loading };
}
