import usePermissions, { OrganizationAdminPermissionName } from ':src/hooks/usePermissions';

export function useCanEditTeamMember() {
  const { hasPermission } = usePermissions();
  return (
    hasPermission(OrganizationAdminPermissionName.UpdateAdmin) ||
    hasPermission(OrganizationAdminPermissionName.UpdateClinicalDirector) ||
    hasPermission(OrganizationAdminPermissionName.UpdateProgramDirector) ||
    hasPermission(OrganizationAdminPermissionName.UpdateBillingManager)
  );
}
