import { useSelectedProgramFilter } from ':src/domains/programs/SelectedProgramFilterContext';
import { SessionDateFilter } from ':src/domains/sessions/utils';
import { SessionDispositionEnum } from '@motivo/guanyin/src/lookup';
import { Sort } from ':src/__generated__/graphql';
import useQueryParams from './useQueryParams';
import useViewer from './useViewer';

export function useQueryVariablesForSessions() {
  const { viewer } = useViewer();
  const { queryParams } = useQueryParams();
  const [programFilter] = useSelectedProgramFilter();

  const {
    minDate: minDateParam,
    maxDate: maxDateParam,
    sessionTypeId: sessionTypeIdParam,
    userId: userIdParam,
    sessionDateFilter: sessionDateFilterParam,
  } = queryParams;

  const sessionTypeId = Number(sessionTypeIdParam) || undefined;
  const userId = Number(userIdParam) || undefined;
  const minDate = minDateParam ? new Date(minDateParam) : undefined;
  const maxDate = maxDateParam ? new Date(maxDateParam) : undefined;

  const queryVariables = {
    organizationId: viewer.organizationId,
    userId,
    afterDate: minDate,
    beforeDate: maxDate,
    sessionTypeId,
    noShowOnly: sessionDateFilterParam === SessionDateFilter.MISSED,
    sort: sessionDateFilterParam === SessionDateFilter.UPCOMING ? Sort.Asc : Sort.Desc,
    sessionDispositionIds: [
      SessionDispositionEnum.INITIAL,
      SessionDispositionEnum.COMPLETE,
      SessionDispositionEnum.CHARGED,
    ],
    programId: programFilter?.id,
  };
  return queryVariables;
}
