import { gql } from ':src/__generated__/gql';

export const organizationProgramById = gql(/* GraphQL */ `
  query OrganizationProgramByIdQuery($organizationProgramId: String!) {
    organizationProgramById(organizationProgramId: $organizationProgramId) {
      id
      name
      requiresNotesInReports
      introCallPreferenceId
      requiresSupervisorAdminHours
    }
  }
`);

export const superviseesByOrganizationProgramId = gql(/* GraphQL */ `
  query SuperviseesByOrganizationProgramIdQuery(
    $organizationProgramId: String!
    $searchQuery: String
  ) {
    superviseesByOrganizationProgramId(
      organizationProgramId: $organizationProgramId
      searchQuery: $searchQuery
    ) {
      result {
        id
        firstName
        lastName
        fullName
        supervisorMatches {
          id
          supervisorId
          matchStatusId
        }
      }
      total
    }
  }
`);

export const organizationSupervisorsOutsideProgram = gql(/* GraphQL */ `
  query OrganizationSupervisorsOutsideProgramQuery(
    $matchStatusId: Int
    $limit: Int!
    $offset: Int!
    $searchQuery: String
    $programId: String
  ) {
    organizationSupervisors(
      matchStatusId: $matchStatusId
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      programId: $programId
    ) {
      result {
        supervisor {
          id
          firstName
          lastName
          fullName
          superviseeMatches {
            id
            matchStatusId
          }
        }
      }
    }
  }
`);

export const organizationSuperviseesOutsideProgram = gql(/* GraphQL */ `
  query OrganizationSuperviseesOutsideProgram(
    $limit: Int!
    $offset: Int!
    $organizationId: Int!
    $filter: UsersByOrganizationIdQueryFilter
    $searchQuery: String
    $sortByMostRecentSession: Boolean
    $programId: String
  ) {
    usersByOrganizationId(
      limit: $limit
      offset: $offset
      organizationId: $organizationId
      filter: $filter
      searchQuery: $searchQuery
      sortByMostRecentSession: $sortByMostRecentSession
      programId: $programId
    ) {
      result {
        id
        firstName
        lastName
        fullName
        supervisorMatches {
          id
          supervisorId
          matchStatusId
        }
      }
      total
    }
  }
`);

export const organizationProgramDirectorsByOrganizationIdQuery = gql(/* GraphQL */ `
  query OrganizationProgramDirectorsByOrganizationIdQuery($organizationId: Int!) {
    organizationProgramDirectorsByOrganizationId(organizationId: $organizationId) {
      id
      firstName
      lastName
      fullName
      organizationPrograms {
        id
      }
    }
  }
`);
