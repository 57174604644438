import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface MoFormControlLabelProps extends Omit<FormControlLabelProps, 'classes'> {}

export function MoFormControlLabel(props: MoFormControlLabelProps) {
  const theme = useTheme();
  return (
    <FormControlLabel
      sx={{ marginBottom: 0 }}
      componentsProps={{
        typography: {
          color: theme.palette.text.primary,
        },
      }}
      {...props}
    />
  );
}
