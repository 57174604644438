import { gql } from ':src/__generated__/gql';
import { ViewerQueryQuery } from ':src/__generated__/graphql';
import { useSuspenseQuery } from '@apollo/client';

export const viewerQuery = gql(/* GraphQL */ `
  query ViewerQuery {
    viewer {
      id
      firstName
      lastName
      fullName
      email
      phone
      mediumAvatar
      organizationId
      organizationPrograms {
        id
        name
      }
      scope
      organizationRoleId
      permissions {
        permissionNames
        programIds
      }
      organization {
        id
        name
        clinicalSettingId
        requiresNotesInReports
        requiresSupervisorAdminHours
        isEnterprise
        usesInvoice
        hasAssignedEmployerCareManager
        requiresPaymentInformation
        supervisionAvailability
        introCallPreferenceId
        populations {
          id
          name
        }
        specialties {
          id
          name
        }
      }
      featureFlags
      openIntroRequestsCount
      timezone
      canAccessBillableWork
      organizationPrograms {
        id
        name
      }
    }
  }
`);

type NonNullableOrganization = NonNullable<ViewerQueryQuery['viewer']['organization']>;

type ViewerWithOrganizationId = Omit<
  ViewerQueryQuery['viewer'],
  'organizationId' | 'organization'
> & {
  organizationId: number;
  organization: NonNullableOrganization;
};

export default function useViewer(options = {}) {
  const {
    data: { viewer },
    refetch,
  } = useSuspenseQuery(viewerQuery, options);

  return { viewer: viewer as ViewerWithOrganizationId, refetch };
}
