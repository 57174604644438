import React from 'react';
import { Box, Divider } from '@mui/material';
import useQueryParams from ':src/hooks/useQueryParams';
import { EmptyOrLoadingState } from '@motivo/guanyin/src/components';
import { useQuery } from '@apollo/client';
import { SessionsQueryQuery } from ':src/__generated__/graphql';
import SessionsFilters from './SessionsFilters';
import AvatarHeader from './AvatarHeader';
import { userByIdQuery } from '../queries';
import SessionsTable from './SessionsTable';

type SessionsCardProps = {
  sessionData: SessionsQueryQuery['sessions'] | undefined;
  organizationId: number;
  sessionLoading: boolean;
  shouldDisplaySessionNotes: boolean;
};

export default function SessionsCard({
  sessionData,
  organizationId,
  sessionLoading,
  shouldDisplaySessionNotes,
}: SessionsCardProps) {
  const sessions = sessionData?.result;
  const { queryParams } = useQueryParams();
  const { userId } = queryParams;

  const { data: userData, loading: userQueryLoading } = useQuery(userByIdQuery, {
    variables: {
      id: Number(userId),
      deletedForOrganizationId: organizationId,
    },
    skip: !userId,
  });

  if (userQueryLoading || sessionLoading || !sessions) {
    return (
      <Box py={4}>
        <EmptyOrLoadingState loading />
      </Box>
    );
  }
  const user = userData?.userById;

  const hasSelectedSupervisor = userId
    ? sessions?.some((session) => session.createdBy === Number(userId))
    : false;

  return (
    <>
      <Box p={4}>
        <SessionsFilters
          organizationId={organizationId}
          shouldShowNotesExport={shouldDisplaySessionNotes}
        />
      </Box>
      <Divider />
      {user && (
        <>
          <AvatarHeader user={user} isSupervisor={hasSelectedSupervisor} />
          <Divider sx={{ mb: 4 }} />
        </>
      )}
      <SessionsTable
        userId={user?.id}
        sessionData={sessionData}
        sessionLoading={sessionLoading}
        hasSelectedSupervisor={hasSelectedSupervisor}
        userQueryLoading={userQueryLoading}
        shouldDisplaySessionNotes={shouldDisplaySessionNotes}
      />
    </>
  );
}
