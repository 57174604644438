import { Box, Chip, styled } from '@mui/material';
import React from 'react';
import { AttendanceStatusEnum } from '../../utils';

export type AttendanceDisplayProps = {
  attendanceType: AttendanceStatusEnum;
  partialAttendanceMinutes?: number | null;
  duration: number;
};

type AttendanceChipProps = {
  color: string;
  backgroundColor: string;
  label: string;
};

const AttendanceLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box display="flex" alignItems="center">
      {children}
    </Box>
  );
};

const CHIP_HEIGHT = 40;
const StyledChip = styled(Chip)(() => ({
  fontSize: '14px',
  fontWeight: 600,
  borderRadius: CHIP_HEIGHT / 2,
  height: CHIP_HEIGHT,
}));

const AttendanceChip = ({ color, backgroundColor, label }: AttendanceChipProps) => {
  return (
    <AttendanceLabel>
      <StyledChip
        label={label}
        sx={{ backgroundColor: { backgroundColor }, color: { color }, mt: { xs: 1, md: 0 } }}
      />
    </AttendanceLabel>
  );
};

export const AttendanceDisplay = ({
  attendanceType,
  partialAttendanceMinutes,
}: AttendanceDisplayProps) => {
  switch (attendanceType) {
    case AttendanceStatusEnum.ENTIRE_SESSION:
      return <AttendanceChip color="#0B5D0B" backgroundColor="#B8E6B8" label="Attended" />;
    case AttendanceStatusEnum.PARTIAL_SESSION:
      return (
        <AttendanceChip
          color="#5C4300"
          backgroundColor="#FFE3AD"
          label={`Partial attendance - ${partialAttendanceMinutes} min`}
        />
      );
    case AttendanceStatusEnum.RESCHEDULED:
      return (
        <AttendanceChip color="#444243" backgroundColor="#DEDCDD" label="Cancelled with notice" />
      );
    case AttendanceStatusEnum.NO_SHOW:
      return (
        <AttendanceChip color="#860000" backgroundColor="#EEADAD" label="No show / Late cancel" />
      );
    default:
      return null;
  }
};
