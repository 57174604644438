import useQueryParams from ':src/hooks/useQueryParams';
import {
  OrganizationSuperviseesOutsideProgramQuery,
  OrganizationSupervisorsQuery,
  SuperviseesByOrganizationProgramIdQueryQuery,
} from ':src/__generated__/graphql';
import {
  EmptyOrLoadingState,
  MoButton,
  MoCard,
  MoPersistentTooltip,
} from '@motivo/guanyin/src/components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

const StyledButton = styled(Button)`
  background-color: white;
  border-color: #cecece;
  &:hover {
    border-color: #cecece;
  }
`;

type ProgramUserType =
  // @ts-expect-error TS(2339) FIXME: Property 'result' does not exist on type '{ __type... Remove this comment to see the full error message
  | SuperviseesByOrganizationProgramIdQueryQuery['superviseesByOrganizationProgramId']['result'][number];

type UsersNotInProgramType =
  | OrganizationSuperviseesOutsideProgramQuery['usersByOrganizationId']['result'][number]
  // @ts-expect-error TS(2339) FIXME: Property 'result' does not exist on type '{ __type... Remove this comment to see the full error message
  | OrganizationSupervisorsQuery['organizationSupervisors']['result'][number]['supervisor'];

type UserType = UsersNotInProgramType | ProgramUserType;

type UserSectionProps = {
  title: string;
  users?: UserType[];
  selectedUserIds: number[];
  onToggleUserSelected: (userId: number, force?: boolean) => void;
  isSupervisorPage: boolean;
  loading?: boolean;
  initialUsersInProgram?: ProgramUserType[];
};

type UserSectionCardContentProps = {
  loading: boolean;
  users: UserType[];
  selectedUserIds: number[];
  onToggleUserSelected: (userId: number, force?: boolean) => void;
  onToggleAllUsersSelected: () => void;
  areAllUsersSelected: boolean;
};

function UserSectionCardContent({
  loading,
  users,
  selectedUserIds,
  onToggleUserSelected,
  onToggleAllUsersSelected,
  areAllUsersSelected,
}: UserSectionCardContentProps) {
  if (loading) {
    return <EmptyOrLoadingState loading={loading} />;
  }
  if (users.length === 0) {
    return <EmptyOrLoadingState image={null}>No users found</EmptyOrLoadingState>;
  }
  return (
    <>
      <MoButton
        variant="text"
        onClick={() => onToggleAllUsersSelected()}
        sx={{
          paddingLeft: 0,
          color: '#323232',
          textDecoration: 'underline',
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        {areAllUsersSelected ? 'Select none' : 'Select all'}
      </MoButton>

      {users.map((user) => {
        const isSelected = selectedUserIds.includes(user.id);
        return (
          <div key={user.id}>
            <FormControlLabel
              key={user.id}
              control={
                <Checkbox
                  checked={isSelected}
                  onChange={() => onToggleUserSelected(user.id)}
                  value={user.id}
                />
              }
              label={user.fullName as string}
            />
          </div>
        );
      })}
    </>
  );
}

function UserSection({
  title,
  users,
  selectedUserIds,
  onToggleUserSelected,
  isSupervisorPage,
  loading,
}: UserSectionProps) {
  const searchKey = title.toLowerCase().replace(/ /g, '-');
  const { updateQueryParams, queryParams } = useQueryParams();
  const searchInput = queryParams[searchKey];

  const usersWithoutActiveMatches = users;

  const areAllUsersSelected = users
    ? // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      usersWithoutActiveMatches.every((user) => selectedUserIds.includes(user.id))
    : false;

  const handleToggleAllUsersSelected = () => {
    if (areAllUsersSelected) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      for (const user of usersWithoutActiveMatches) {
        onToggleUserSelected(user.id, false);
      }
    } else {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      for (const user of usersWithoutActiveMatches) {
        if (!selectedUserIds.includes(user.id)) {
          onToggleUserSelected(user.id, true);
        }
      }
    }
  };

  const handleClearInput = () => {
    updateQueryParams({ [searchKey]: '' });
  };

  const filteredUsers = (users || []).filter((user) => {
    return user.fullName.match(new RegExp(searchInput, 'ig'));
  });

  const sortedUsers = useMemo(() => {
    if (!filteredUsers) return [];
    return [...filteredUsers].sort((a, b) => {
      if (a.fullName < b.fullName) {
        return -1;
      }
      if (a.fullName > b.fullName) {
        return 1;
      }
      return 0;
    });
  }, [filteredUsers]);

  return (
    <>
      <Box display="flex" gap={1} alignItems="center" mb={3}>
        <Typography variant="h5" fontWeight="bold">
          {title}
        </Typography>
        <MoPersistentTooltip
          titleContent={
            <Typography padding={1}>
              {!isSupervisorPage &&
                (title === 'Available' ? (
                  <>
                    These are all the supervisees available to add to a program.{' '}
                    <strong>A supervisee can only be in one program at a time.</strong>
                  </>
                ) : (
                  <>These are all the supervisees currently in this program. </>
                ))}
              {isSupervisorPage &&
                (title === 'Available' ? (
                  <>
                    These are all the supervisors connected to your organization. They can be part
                    of multiple programs.
                  </>
                ) : (
                  <>
                    These are all the supervisors that are part of this program.{' '}
                    <strong>A supervisor can be removed if they are currently unmatched.</strong>
                  </>
                ))}
            </Typography>
          }
          placement="top-start"
          arrow
        >
          {({ toggleTooltip }) => (
            <IconButton onClick={toggleTooltip} aria-label="Close">
              <InfoOutlinedIcon />
            </IconButton>
          )}
        </MoPersistentTooltip>
      </Box>
      <TextField
        fullWidth
        sx={{ marginBottom: '16px' }}
        label={isSupervisorPage ? 'Supervisor' : 'Supervisee'}
        value={searchInput || ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClearInput}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          updateQueryParams({ [searchKey]: e.target.value });
        }}
      />
      <MoCard sx={{ height: '550px', overflowY: 'auto' }}>
        <UserSectionCardContent
          // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
          loading={loading}
          users={sortedUsers}
          selectedUserIds={selectedUserIds}
          onToggleUserSelected={onToggleUserSelected}
          onToggleAllUsersSelected={handleToggleAllUsersSelected}
          areAllUsersSelected={areAllUsersSelected}
        />
      </MoCard>
    </>
  );
}

type ManageProgramsUsersProps = {
  usersInProgram?: ProgramUserType[];
  usersNotInProgram?: UsersNotInProgramType[];
  usersInProgramLoading: boolean;
  usersNotInProgramLoading: boolean;
  onSubmit: (userIds: number[]) => void;
  isSupervisorPage: boolean;
};

export function ManageProgramsUsers({
  usersInProgram: initialUsersInProgram,
  usersNotInProgram: initialUsersNotInProgram,
  usersInProgramLoading,
  usersNotInProgramLoading,
  isSupervisorPage,
  onSubmit,
}: ManageProgramsUsersProps) {
  const [usersNotInProgram, setUsersNotInProgram] = useState(initialUsersNotInProgram || []);
  const [usersInProgram, setUsersInProgram] = useState(initialUsersInProgram || []);
  const [selectedUsersNotInProgramIds, setSelectedUsersNotInProgramIds] = useState(
    new Set<number>(),
  );
  const [selectedProgramUserIds, setSelectedProgramUserIds] = useState(new Set<number>());

  const handleCheckboxChange = (
    userId: number,
    force: boolean | undefined,
    setSelectedUserIds: React.Dispatch<React.SetStateAction<Set<number>>>,
  ) => {
    setSelectedUserIds((prevSelectedUserIds) => {
      const newUserIds = new Set(prevSelectedUserIds);
      if (force !== undefined) {
        if (force) {
          newUserIds.add(userId);
        } else {
          newUserIds.delete(userId);
        }
      } else if (prevSelectedUserIds.has(userId)) {
        newUserIds.delete(userId);
      } else {
        newUserIds.add(userId);
      }
      return newUserIds;
    });
  };

  const handleMoveUsers = (destination: 'available' | 'inProgram') => {
    let newUsersInProgram = [...usersInProgram];
    let newUsersNotInProgram = [...usersNotInProgram];
    if (destination === 'available') {
      const selectedUsersInProgram = newUsersInProgram.filter((user) =>
        selectedProgramUserIds.has(user.id),
      );
      newUsersNotInProgram = [...newUsersNotInProgram, ...selectedUsersInProgram];
      newUsersInProgram = newUsersInProgram.filter((user) => !selectedProgramUserIds.has(user.id));
      setSelectedProgramUserIds(new Set<number>());
    } else {
      const selectedUsersNotInProgram = newUsersNotInProgram.filter((user) =>
        selectedUsersNotInProgramIds.has(user.id),
      );
      newUsersInProgram = [...newUsersInProgram, ...selectedUsersNotInProgram];
      newUsersNotInProgram = newUsersNotInProgram.filter(
        (user) => !selectedUsersNotInProgramIds.has(user.id),
      );
      setSelectedUsersNotInProgramIds(new Set<number>());
    }

    setUsersNotInProgram(newUsersNotInProgram);
    setUsersInProgram(newUsersInProgram);
  };

  useEffect(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
    setUsersNotInProgram(initialUsersNotInProgram);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
    setUsersInProgram(initialUsersInProgram);
  }, [initialUsersNotInProgram, initialUsersInProgram]);

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="space-between">
      <Grid item xs={5}>
        <UserSection
          title="Available"
          users={usersNotInProgram}
          loading={usersNotInProgramLoading}
          selectedUserIds={[...selectedUsersNotInProgramIds]}
          onToggleUserSelected={(userId, force) =>
            handleCheckboxChange(userId, force, setSelectedUsersNotInProgramIds)
          }
          isSupervisorPage={isSupervisorPage}
        />
      </Grid>
      <Grid item xs={2} container justifyContent="center">
        <Box display="flex" flexDirection="column" gap={3}>
          <StyledButton variant="outlined" onClick={() => handleMoveUsers('inProgram')}>
            <ArrowForwardIosIcon sx={{ color: '#323232' }} />
          </StyledButton>
          <StyledButton variant="outlined" onClick={() => handleMoveUsers('available')}>
            <ArrowBackIosIcon sx={{ color: '#323232' }} />
          </StyledButton>
        </Box>
      </Grid>
      <Grid item xs={5}>
        <UserSection
          title="In program"
          users={usersInProgram}
          loading={usersInProgramLoading}
          selectedUserIds={[...selectedProgramUserIds]}
          onToggleUserSelected={(userId, force) =>
            handleCheckboxChange(userId, force, setSelectedProgramUserIds)
          }
          initialUsersInProgram={initialUsersInProgram}
          isSupervisorPage={isSupervisorPage}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <MoButton
          variant="contained"
          color="primary"
          onClick={() => onSubmit(usersInProgram.map((user) => user.id))}
        >
          Save changes
        </MoButton>
      </Grid>
    </Grid>
  );
}
