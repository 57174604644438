import React, { useState } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { SessionTypeEnum } from '@motivo/guanyin/src/lookup';
import useQueryParams from ':src/hooks/useQueryParams';
import { useLazyQuery, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import SnackbarState from ':src/hooks/SnackbarState';
import { downloadFile, downloadCSV } from ':src/utils/downloadUtils';
import { MoSelect, StartsOnSundayLuxonAdapter } from '@motivo/guanyin/src/components';
import { useSelectedProgramFilter } from ':src/domains/programs/SelectedProgramFilterContext';
import { usersByOrganizationId } from ':src/domains/supervisees/queries';
import { useQueryVariablesForSessions } from ':src/hooks/useQueryVariablesForSessions';
import {
  fetchAttendanceReport,
  getDateStringFromParams,
  getMinAndMaxDateForParams,
  SessionDateFilter,
} from '../../utils';
import generateSessionCSV from './generateSessionsCSV';
import { sessionsQuery } from '../queries';

type SessionsFiltersProps = {
  minDate?: string;
  maxDate?: string;
  sessionTypeId?: string;
  organizationId?: number;
  shouldShowNotesExport?: boolean;
};
type SessionsFilterVariableProps = Omit<SessionsFiltersProps, 'shouldShowNotesExport'>;

export default function SessionsFilters({
  organizationId,
  shouldShowNotesExport,
}: SessionsFiltersProps) {
  const variables = useQueryVariablesForSessions();
  const { openSnackbar } = SnackbarState.useContainer();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { queryParams, pushUpdateQueryParams } = useQueryParams();
  const [programFilter] = useSelectedProgramFilter();
  const [isExportingFile, setIsExportingFile] = useState(false);

  const {
    sessionTypeId: sessionTypeIdParam,
    userId,
    minDate: minDateParam,
    maxDate: maxDateParam,
    sessionDateFilter,
  } = queryParams;
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [isExportMenuOpen, setIsExportMenuOpen] = useState(false);

  const minDateFilter = DateTime.fromISO(minDateParam).toISODate() || undefined;
  const maxDateFilter = DateTime.fromISO(maxDateParam).toISODate() || undefined;

  const [queryVariables, setQueryVariables] = useState<SessionsFilterVariableProps>({
    minDate: minDateFilter,
    maxDate: maxDateFilter,
    sessionTypeId: sessionTypeIdParam,
  });

  const { data, loading } = useQuery(usersByOrganizationId, {
    variables: {
      // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      organizationId,
      programId: programFilter?.id,
      limit: 999,
      offset: 0,
      isRemovedTab: false,
    },
  });
  const organizationUsers = data?.usersByOrganizationId?.result;

  const onFilterMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsFilterMenuOpen(true);
  };
  const onFilterMenuClose = () => {
    setAnchorEl(null);
    setIsFilterMenuOpen(false);
  };

  const onExportMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsExportMenuOpen(true);
  };

  const onExportMenuClose = () => {
    setAnchorEl(null);
    setIsExportMenuOpen(false);
  };

  const onFilterReset = () => {
    const { minDate: minDateReset, maxDate: maxDateReset } =
      getMinAndMaxDateForParams(sessionDateFilter);
    pushUpdateQueryParams({
      // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      sessionTypeId: undefined,
      // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      userId: undefined,
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      minDate: minDateReset || undefined,
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      maxDate: maxDateReset || undefined,
    });
  };
  const onApplyFilters = () => {
    onFilterMenuClose();
    pushUpdateQueryParams({
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      sessionTypeId: queryVariables.sessionTypeId,
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      minDate: queryVariables.minDate,
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      maxDate: queryVariables.maxDate,
      page: '1',
    });
  };
  const onDownloadNotesPDF = async () => {
    openSnackbar('Generating file...');
    setIsExportingFile(true);
    const startDate = DateTime.fromISO(minDateParam).isValid
      ? DateTime.fromISO(minDateParam).toJSDate()
      : DateTime.local().startOf('day').minus({ month: 1 }).toJSDate();
    try {
      const blob = await fetchAttendanceReport({
        startDate,
        // @ts-expect-error TS(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
        endDate: maxDateFilter,
        // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
        userId: Number(userId) || null,
        organizationId,
        noShowOnly: sessionDateFilter === SessionDateFilter.MISSED,
        programId: programFilter?.id,
      });
      const fileName = `Motivo session notes ${DateTime.fromJSDate(startDate).toLocaleString(
        DateTime.DATE_FULL,
      )} to ${getDateStringFromParams(maxDateParam)}`;
      downloadFile(blob, `${fileName}.pdf`);
      openSnackbar('File generated successfully');
    } catch (e) {
      openSnackbar('An error occurred while generating the report');
    } finally {
      setIsExportingFile(false);
    }
  };

  const [fetchAndDownloadSessionCSVData] = useLazyQuery(sessionsQuery, {
    variables: {
      ...variables,
      limit: 10000,
      offset: 0,
    },
    onCompleted(data) {
      if (!data.sessions || !isExportingFile) return;
      const csvString = generateSessionCSV(data.sessions.result);
      const maxDateString = maxDateParam ? ` to ${getDateStringFromParams(maxDateParam)}` : '';
      const fileName = `Motivo sessions ${getDateStringFromParams(minDateParam)}${maxDateString}`;
      downloadCSV(csvString, `${fileName}.csv`);
      setIsExportingFile(false);
    },
  });

  const onDownloadSessionCSV = () => {
    setIsExportingFile(true);
    fetchAndDownloadSessionCSVData();
  };

  const isExportDisabled = isExportingFile;
  const allowExportingPDF =
    sessionDateFilter !== SessionDateFilter.UPCOMING && shouldShowNotesExport;

  if (loading) {
    return null;
  }
  return (
    <LocalizationProvider dateAdapter={StartsOnSundayLuxonAdapter}>
      <Box display="flex" justifyContent="space-between">
        <Box width="250px">
          {organizationUsers && (
            <MoSelect
              label="Supervisees"
              name="userId"
              value={userId}
              onChange={(e) => pushUpdateQueryParams({ userId: e.target.value as string })}
            >
              <MenuItem value="">All supervisees</MenuItem>
              {organizationUsers.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.fullName}
                </MenuItem>
              ))}
            </MoSelect>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button onClick={onFilterMenuOpen} variant="outlined" color="neutral">
            Filters
          </Button>
          <Menu
            open={isFilterMenuOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={onFilterMenuClose}
          >
            <Box display="flex" flexDirection="column" gap={3} p={3}>
              <Typography fontWeight="bold">Date range</Typography>
              <DesktopDatePicker
                label="Start date"
                format="LL/dd/yyyy"
                maxDate={
                  queryVariables.maxDate ? DateTime.fromISO(queryVariables.maxDate) : undefined
                }
                slotProps={{
                  textField: {
                    name: 'minDate',
                    label: 'Start date',
                    fullWidth: true,
                    helperText: null,
                  },
                }}
                onChange={(newDate: DateTime | null) => {
                  if (newDate && newDate.isValid) {
                    setQueryVariables({
                      ...queryVariables,
                      minDate: newDate.toISODate(),
                    });
                  }
                }}
                value={queryVariables.minDate ? DateTime.fromISO(queryVariables.minDate) : null}
              />
              <DesktopDatePicker
                label="End date"
                format="LL/dd/yyyy"
                minDate={
                  queryVariables.minDate ? DateTime.fromISO(queryVariables.minDate) : undefined
                }
                slotProps={{
                  textField: {
                    name: 'maxDate',
                    label: 'End date',
                    fullWidth: true,
                    helperText: null,
                  },
                }}
                onChange={(newDate: DateTime | null) => {
                  if (newDate?.isValid) {
                    setQueryVariables({
                      ...queryVariables,
                      maxDate: newDate.toISODate(),
                    });
                  }
                }}
                value={queryVariables.maxDate ? DateTime.fromISO(queryVariables.maxDate) : null}
              />
              <Divider />
              <Typography fontWeight="bold">Session type</Typography>
              <RadioGroup
                onChange={(e) =>
                  setQueryVariables({
                    ...queryVariables,
                    sessionTypeId: e.target.value as string,
                  })
                }
                value={queryVariables.sessionTypeId || ''}
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel
                  value={SessionTypeEnum.INDIVIDUAL}
                  control={<Radio />}
                  label="Individual"
                />
                <FormControlLabel value={SessionTypeEnum.DYAD} control={<Radio />} label="Dyad" />
                <FormControlLabel value={SessionTypeEnum.GROUP} control={<Radio />} label="Group" />
              </RadioGroup>
              <Box justifyContent="space-between">
                <Button onClick={onFilterReset} color="neutral">
                  Reset options
                </Button>
                <Button variant="outlined" color="neutral" onClick={onApplyFilters}>
                  Apply filter
                </Button>
              </Box>
            </Box>
          </Menu>
          <Menu
            open={isExportMenuOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={onExportMenuClose}
          >
            <MenuItem onClick={onDownloadSessionCSV}>Sessions - CSV</MenuItem>
            {allowExportingPDF && (
              <MenuItem onClick={onDownloadNotesPDF}>Session notes - PDF</MenuItem>
            )}
          </Menu>
          <Button
            variant="outlined"
            color="neutral"
            onClick={onExportMenuOpen}
            disabled={isExportDisabled}
          >
            Export
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
