import { gql } from ':src/__generated__/gql';

export const createOrganizationProgramMutation = gql(/* GraphQL */ `
  mutation CreateOrganizationProgramMutation($input: CreateOrganizationProgramInput!) {
    createOrganizationProgram(input: $input) {
      success
    }
  }
`);

export const deleteOrganizationProgramMutation = gql(/* GraphQL */ `
  mutation DeleteOrganizationProgramMutation($input: DeleteOrganizationProgramInput!) {
    deleteOrganizationProgram(input: $input) {
      success
    }
  }
`);

export const updateSuperviseesInProgramMutation = gql(/* GraphQL */ `
  mutation UpdateSuperviseesInProgramMutation($input: UpdateSuperviseesInProgramInput!) {
    updateSuperviseesInProgram(input: $input) {
      success
    }
  }
`);

export const assignOrganizationProgramDirectorMutation = gql(/* GraphQL */ `
  mutation AssignOrganizationProgramDirectorMutation(
    $input: AssignOrganizationProgramDirectorInput!
  ) {
    assignOrganizationProgramDirector(input: $input) {
      success
    }
  }
`);

export const updateOrganizationProgramSettingsMutation = gql(/* GraphQL */ `
  mutation UpdateOrganizationProgramSettingsMutation(
    $input: UpdateOrganizationProgramSettingsInput!
  ) {
    updateOrganizationProgramSettings(input: $input) {
      success
    }
  }
`);
