import React from 'react';
import { default as MUISnackbar } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarState from '../hooks/SnackbarState';

export default function Snackbar() {
  const { closeSnackbar, isSnackbarOpen, snackbarMessage } = SnackbarState.useContainer();

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={isSnackbarOpen}
      autoHideDuration={6000}
      onClose={closeSnackbar}
      message={snackbarMessage}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackbar}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}
