import React, { FunctionComponent, createContext, useContext, useState } from 'react';
import { usePrograms } from './hooks/usePrograms';

type SelectedProgram = {
  id: string;
  name: string;
};

function useContextHook(selectedProgram: SelectedProgram | null) {
  return useState<SelectedProgram | null>(selectedProgram);
}

// @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
const SelectedProgramFilterContext = createContext<ReturnType<typeof useContextHook>>(null);

export const SelectedProgramFilterProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { programs } = usePrograms();
  const selectedProgram = programs?.length === 1 ? programs[0] : null;

  return (
    <SelectedProgramFilterContext.Provider value={useContextHook(selectedProgram)}>
      {children}
    </SelectedProgramFilterContext.Provider>
  );
};

export function useSelectedProgramFilter() {
  const context = useContext(SelectedProgramFilterContext);
  if (!context) {
    throw new Error('Missing context');
  }

  return context;
}
