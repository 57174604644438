import { gql } from ':src/__generated__/gql';

export const introRequestsQuery = gql(/* GraphQL */ `
  query IntroRequestsQuery {
    introRequests {
      total
      result {
        id
        scheduledAt
        introRequestStatusId
        availableSlots
        createdAt
        supervisor {
          id
          firstName
          lastName
          fullName
          avatarUrl
        }
        organizationAdmin {
          id
          firstName
          lastName
          fullName
        }
      }
    }
  }
`);

export const introRequestsDashboardNotificationQuery = gql(/* GraphQL */ `
  query IntroRequestsDashboardNotificationQuery {
    introRequests(waitingForViewer: true) {
      result {
        id
      }
      total
    }
  }
`);

export const introRequestByIdQuery = gql(/* GraphQL */ `
  query IntroRequestByIdQuery($id: String!) {
    introRequestById(id: $id) {
      id
      introRequestDeclineReasonId
      introRequestStatusId
      meetingUrl
      joinUrl
      scheduledAt
      createdAt
      updatedAt
      organizationAdmin {
        id
        firstName
        lastName
        fullName
        actorId
        organization {
          id
          name
          clinicalSettingId
          requiresNotesInReports
          requiresSupervisorAdminHours
        }
      }
      supervisor {
        id
        firstName
        lastName
        fullName
        actorId
        displayName
        supervisorProfile {
          quote
          bio
        }
        usersModalities {
          modality {
            id
            name
          }
        }
        usersSpecialties {
          specialty {
            id
            name
          }
        }
        usersDegrees {
          degree {
            id
            name
          }
          universitySuggestedName
        }
        usersLicenses {
          license {
            name
            displayName
          }
          licenseNumber
          usState {
            name
          }
          isAbleToSupervise
        }
        usersSupervisionCredentials {
          supervisionCredential {
            id
            name
          }
          usState {
            id
            abbreviation
          }
        }
      }
      match {
        id
        createdAt
      }
      availableSlots
    }
  }
`);
