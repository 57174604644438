'use client';

import React from 'react';
import { Field, useField } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { MoTextInput, MoTextInputProps } from '../MoTextInput';

export interface MoTextFieldInputProps extends MoTextInputProps {
  name: string;
  validate?: FieldValidator<string>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export function MoTextInputField({
  name,
  type,
  helperText,
  validate,
  onChange = () => {},
  ...rest
}: MoTextFieldInputProps) {
  let helperTextLines: string[] = [];
  if (helperText) {
    helperTextLines = Array.isArray(helperText) ? helperText : [helperText];
  }
  const { meta } = useField(name);

  if (meta.submitFailed && meta.error) {
    helperTextLines.push(...meta.error);
  }

  return (
    <Field<string>
      name={name}
      validate={validate}
      subscription={{
        error: true,
        value: true,
        submitFailed: true,
        valid: true,
      }}
      format={(value) => {
        if (type === 'number') {
          // @ts-expect-error
          if (value === 0) {
            return '0';
          }
          return value ? Number(value).toString() : '';
        }
        return value;
      }}
      parse={(value) => {
        if (type === 'number') {
          return value ? Number(value) : value;
        }
        return value;
      }}
      render={({ input, meta }) => {
        const props = {
          ...input,
          type,
          error: meta.submitFailed && !meta.valid,
          helperText: helperTextLines.join('\n'),
          onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            input.onChange(e);
            onChange(e);
          },
          ...rest,
        };

        return <MoTextInput {...props} />;
      }}
    />
  );
}
