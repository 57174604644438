import { ProgramType, usePrograms } from ':src/domains/programs/hooks/usePrograms';
import { useSelectedProgramFilter } from ':src/domains/programs/SelectedProgramFilterContext';
import usePermissions from ':src/hooks/usePermissions';
import { MoLink } from '@motivo/guanyin/src/components';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import useViewer from ':src/hooks/useViewer';
import { OrganizationRoleEnum } from '@motivo/guanyin/src/lookup';
import { OrganizationAdminPermissionName } from ':src/__generated__/graphql';

const PinkBanner: FunctionComponent<{
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}> = ({ leftContent, rightContent }) => {
  return (
    <Box bgcolor="primary.main" color="white" px={4}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>{leftContent}</div>
        <div>{rightContent}</div>
      </Box>
    </Box>
  );
};

export const ProgramFilterBanner: FunctionComponent<{}> = () => {
  const { programs } = usePrograms();
  const { viewer } = useViewer();
  const { hasPermission } = usePermissions();
  const [selectedProgram, setSelectedProgram] = useSelectedProgramFilter();

  if (!hasPermission(OrganizationAdminPermissionName.SelectPrograms) || programs.length === 0) {
    return null;
  }

  return (
    <PinkBanner
      leftContent={
        <>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography fontWeight="bold">Program:</Typography>
            <Autocomplete
              sx={{
                minWidth: 300,
              }}
              autoHighlight
              clearOnBlur
              value={selectedProgram}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel
                  placeholder={selectedProgram?.name || 'Select Program'}
                  sx={{
                    backgroundColor: 'primary.main',
                    fieldset: { border: 'none' },
                    input: {
                      color: 'white',
                      '&::placeholder': {
                        color: 'white',
                        opacity: 1,
                      },
                    },
                    svg: {
                      color: 'white',
                    },
                  }}
                />
              )}
              options={programs}
              disableClearable={
                programs.length < 2 && viewer.organizationRoleId !== OrganizationRoleEnum.ADMIN
              }
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue: ProgramType | null) => {
                setSelectedProgram(newValue);
              }}
            />
          </Box>
        </>
      }
      rightContent={
        <>
          <MoLink sx={{ color: 'white' }} to="/programs">
            See all programs
          </MoLink>
        </>
      }
    />
  );
};
