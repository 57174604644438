import { IntroCallPreferences } from '@src/lookup';

export const getAvatarSizeBasedOnAttendeeLength = (length: number) => {
  switch (length) {
    case 1:
      return 5;
    case 2:
      return 4;
    default:
      return 3;
  }
};

export function getIntroCallPreferenceFromId(id: number) {
  switch (id) {
    case IntroCallPreferences.SUPERVISEE_WITH_SUPERVISOR:
      return 'Supervisee with supervisor';
    case IntroCallPreferences.EMPLOYER_ADMIN_WITH_SUPERVISOR:
      return 'Employer admin with supervisor';
    case IntroCallPreferences.NONE:
    default:
      return 'No intro call needed';
  }
}
