import React from 'react';
import { Box } from '@mui/material';
import { Footer } from '@motivo/guanyin/src/components';
import { useLocation } from 'react-router';
import Snackbar from '../Snackbar';
import Header from '../Header';

type LayoutProps = {
  children: React.ReactNode;
};

export default function Layout({ children }: LayoutProps) {
  const location = useLocation();
  const isChatPage = location.pathname.startsWith('/chats');

  return (
    <>
      <Box height="100vh" display="flex" flexDirection="column">
        <Header />
        <Box display="flex" flexGrow={1} overflow={isChatPage ? 'hidden' : 'initial'}>
          {children}
        </Box>
        {!isChatPage && <Footer />}
      </Box>
      <Snackbar />
    </>
  );
}
