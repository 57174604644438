import React from 'react';
import { MoPersistentTooltip, MoRadioField, MoRadioGroup } from '@motivo/guanyin/src/components';
import { Box, FormControl, IconButton, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IntroCallPreferences } from '@motivo/guanyin/src/lookup';

export function IntroCallPreferencesFormField({
  introCallPreferenceId,
}: {
  introCallPreferenceId: number;
}) {
  return (
    <div>
      <Box display="flex" gap={1} alignItems="center" mb={3}>
        <Typography variant="h3">Intro call preferences</Typography>
        <MoPersistentTooltip
          titleContent={
            <Typography>
              <Typography variant="body1" fontWeight={700}>
                No intro call needed
              </Typography>
              <Typography variant="body1">
                An intro call is not necessary to match with a supervisor.
              </Typography>
              <br />
              <Typography variant="body1" fontWeight={700}>
                Employer admin does intro call with supervisor
              </Typography>
              <Typography variant="body1">
                An admin meets with the supervisor before they are matched with any supervisees.
              </Typography>
              <br />
              <Typography variant="body1" fontWeight={700}>
                Supervisee does intro call with supervisor
              </Typography>
              <Typography variant="body1">
                When you add new supervisees, they will go through the directory and match with
                their own supervisor.
              </Typography>
            </Typography>
          }
          placement="right-start"
          arrow
        >
          {({ toggleTooltip }) => (
            <IconButton onClick={toggleTooltip} aria-label="Close">
              <InfoOutlinedIcon />
            </IconButton>
          )}
        </MoPersistentTooltip>
      </Box>
      <FormControl component="fieldset">
        <MoRadioGroup
          label=""
          defaultValue={introCallPreferenceId}
          value={introCallPreferenceId}
          outlined
        >
          <MoRadioField<IntroCallPreferences>
            name="introCallPreferenceId"
            label="No intro call needed"
            parse={(value) => Number(value)}
            value={IntroCallPreferences.NONE}
          />
          <MoRadioField<IntroCallPreferences>
            name="introCallPreferenceId"
            label="Employer admin does intro call with supervisor"
            parse={(value) => Number(value)}
            value={IntroCallPreferences.EMPLOYER_ADMIN_WITH_SUPERVISOR}
          />
          <MoRadioField<IntroCallPreferences>
            name="introCallPreferenceId"
            label="Supervisee does intro call with supervisor"
            parse={(value) => Number(value)}
            value={IntroCallPreferences.SUPERVISEE_WITH_SUPERVISOR}
          />
        </MoRadioGroup>
      </FormControl>
    </div>
  );
}
