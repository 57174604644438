import { SessionsQueryQuery } from ':src/__generated__/graphql';
import json2csv, { parse } from 'json2csv';

type SessionFieldType = NonNullable<SessionsQueryQuery['sessions']>['result'][number];

export default function generateSessionsCSV(
  sessions: NonNullable<SessionsQueryQuery['sessions']>['result'],
) {
  const fields: json2csv.Options<SessionFieldType>['fields'] = [
    {
      label: 'Date',
      value(row: SessionFieldType) {
        return new Date(row.scheduledAt).toLocaleString();
      },
    },
    {
      label: 'Duration',
      value: 'duration',
    },
    {
      label: 'Type',
      value(row: SessionFieldType) {
        return row.sessionType.displayName;
      },
    },
    {
      label: 'Supervisor',
      value(row: SessionFieldType) {
        return `${row.supervisor?.firstName} ${row.supervisor?.lastName}`;
      },
    },
    {
      label: 'Supervisees',
      value(row: SessionFieldType) {
        return row.sessionAttendees
          .map((supervisee) => {
            const programName = supervisee.user.organizationUser?.organizationProgram?.name;
            const name = `${supervisee.user.firstName} ${supervisee.user.lastName}`;

            return programName ? `${name} (${programName})` : name;
          })
          .join('\n');
      },
    },
  ];

  return parse(sessions, {
    fields,
  });
}
